import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import Preloader from '../commons/Preloader/Preloader';
import Search from '../Search/Search';
import { SEX } from '../../constants';

const Home = lazy(() => import('../Home'));
const ProductItems = lazy(() => import('../ProductItems/ProductItems'));
const PageItem = lazy(() => import('../PageItem/PageItem'));
const Basket = lazy(() => import('../Basket/Basket'));
const Favorites = lazy(() => import('../Favorites/Favorites'));
const Profile = lazy(() => import('../Profile/Profile'));
const Admin = lazy(() => import('../Profile/Admin/Admin'));
const AddItem = lazy(() => import('../Profile/Admin/AddItem/AddItem'));
const Orders = lazy(() => import('../Profile/Admin/Orders/Orders'));
const Promocodes = lazy(() => import('../Profile/Admin/Promocodes/Promocodes'));
const InfoPage = lazy(() => import('../Profile/Admin/Info/InfoPage'));
const FindUserPage = lazy(() => import('../Profile/Admin/FindUser/FindUser'));
const FindUs = lazy(() => import('../FindUs/FindUs'));
const Promocode = lazy(() => import('../Promocode/Promocode'));
const Tracking = lazy(() => import('../Tracking/Tracking'));
const Order = lazy(() => import('../Order/Order'));
const Articles = lazy(() => import('../Articles/Articles'));
const Reviews = lazy(() => import('../Reviews/Reviews'));
const Contacts = lazy(() => import('../Contacts/Contacts'));
const Payment = lazy(() => import('../Payment/Payment'));
const Guarantee = lazy(() => import('../Guarantee/Guarantee'));
const Delivery = lazy(() => import('../Delivery/Delivery'));
const Versions = lazy(() => import('../Versions/Versions'));
const CookiePage = lazy(() => import('../commons/Cookie/CookiePage'));
const Policy = lazy(() => import('../Policy/Policy'));
const Terms = lazy(() => import('../Terms/Terms'));
const Error404Page = lazy(() => import('../commons/Error404'));

const ROUTES = {
    HOME: '/',
    PRODUCTS: '/products',
    PRODUCTS_WOMEN: '/products/women',
    PRODUCTS_UNISEX: '/products/unisex',
    PRODUCTS_MEN: '/products/men',
    PRODUCTS_ID: '/products/:sex/:id',
    BASKET: '/basket',
    FAVORITES: '/favorites',
    PROFILE: '/profile',
    ADMIN: '/admin',
    ADMIN_ADDITEM: '/admin/addItem',
    ADMIN_ORDERS: '/admin/orders',
    ADMIN_INFO: '/admin/info',
    ADMIN_FIND_USER: '/admin/find-user',
    ADMIN_PROMOCODES: '/admin/promocodes',
    FIND_US: '/findUs',
    PROMOCODE: '/promocode',
    TRACKING: '/tracking',
    ORDER: '/order',
    ARTICLES: '/articles',
    REVIEWS: '/reviews',
    CONTACTS: '/contacts',
    PAYMENT: '/payment',
    GUARANTEE: '/guarantee',
    DELIVERY: '/delivery',
    VERSIONS: '/versions',
    COOKIE: '/cookie',
    POLICY: '/policy',
    TERMS: '/terms',
    NOT_FOUND: '*',
};

const routesData = [
    { path: ROUTES.HOME, component: Home },
    { path: ROUTES.PRODUCTS, component: ProductItems },
    { path: ROUTES.PRODUCTS_WOMEN, component: ProductItems, props: { sex: SEX.women.value } },
    { path: ROUTES.PRODUCTS_UNISEX, component: ProductItems, props: { sex: SEX.unisex.value } },
    { path: ROUTES.PRODUCTS_MEN, component: ProductItems, props: { sex: SEX.men.value } },
    { path: ROUTES.PRODUCTS_ID, component: PageItem },
    { path: ROUTES.BASKET, component: Basket },
    { path: ROUTES.FAVORITES, component: Favorites },
    { path: ROUTES.PROFILE, component: Profile },
    { path: ROUTES.ADMIN, component: Admin },
    { path: ROUTES.ADMIN_ADDITEM, component: AddItem },
    { path: ROUTES.ADMIN_ORDERS, component: Orders },
    { path: ROUTES.ADMIN_INFO, component: InfoPage },
    { path: ROUTES.ADMIN_PROMOCODES, component: Promocodes },
    { path: ROUTES.ADMIN_FIND_USER, component: FindUserPage },
    { path: ROUTES.FIND_US, component: FindUs },
    { path: ROUTES.PROMOCODE, component: Promocode },
    { path: ROUTES.TRACKING, component: Tracking },
    { path: ROUTES.ORDER, component: Order },
    { path: ROUTES.ARTICLES, component: Articles },
    { path: ROUTES.REVIEWS, component: Reviews },
    { path: ROUTES.CONTACTS, component: Contacts },
    { path: ROUTES.PAYMENT, component: Payment },
    { path: ROUTES.GUARANTEE, component: Guarantee },
    { path: ROUTES.DELIVERY, component: Delivery },
    { path: ROUTES.VERSIONS, component: Versions },
    { path: ROUTES.COOKIE, component: CookiePage },
    { path: ROUTES.POLICY, component: Policy },
    { path: ROUTES.TERMS, component: Terms },
    { path: ROUTES.NOT_FOUND, component: Error404Page },
];

const Main = () => {
    return (
        <main className='main'>
            <div className='wrapper'>
                <div className='main__wrapper flexColumn'>
                    <div className='search-wrapper__mobile'>
                        <Search />
                    </div>
                    <Suspense fallback={<Preloader />}>
                        <Routes>
                            {routesData.map(({ path, component: Component, props }) => (
                                <Route key={path} path={path} element={<Component {...props} />} />
                            ))}
                        </Routes>
                    </Suspense>
                </div>
            </div>
        </main>
    );
};

export default Main;
