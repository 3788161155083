export const filtersInitialState = {
    price: { min: 100, max: 50000 },
    color: [],
    brand: [],
    size: [],
    sex: null,
    type: [],
    permissionToRequest: false,
    // minPrice: 0,
    // maxPrice: 50000,
    // filtersApplied: false
};
