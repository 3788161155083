import './styles/commons/reset.css';
import './styles/fonts.css';
import './styles/imports.scss';
import './styles/screen.scss';
import './styles/filterPriceSlider.scss';
import './styles/popup.scss';

import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import ButtonToTop from './components/commons/ButtonToTop';
import { CookieWarning } from './components/commons/Cookie/CookieWarning';
import Header from './components/Header/Header';
import { InfoBlock } from './components/commons/infoBlock';

const App = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <InfoBlock />
            <ButtonToTop />
            <Header />
            <Main />
            <CookieWarning />
            <Footer />
        </div>
    );
};

export default App;
