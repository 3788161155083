export const ABOUT = {
    title: '21 SPORT / 21 STORE',
    description:
        ' — мультибрендовый магазин одежды, обуви и аксессуаров в Нижнем Новгороде. Мы предлагаем только оригинальную продукцию от ведущих мировых брендов!',
    paragraph2: 'Магазин открыт с 2020 года и находится по адресу: ',
    address: 'Площадь Максима Горького, 4/2 (цокольный этаж).',
    paragraph3: ' Также у нас есть филиал на улице Пискунова, 21. Работает ежедневно с ',
    time: '10:00 до 22:00',
    paragraph4:
        'В нашем ассортименте вы найдете популярные бренды, такие как: Adidas, Nike, Jordan, New Balance, Converse, Vans, а также премиум-бренды: Burberry, Stone Island, Tommy Hilfiger, Armani Exchange, Polo Ralph Lauren, Lacoste.',
    paragraph5: 'Мы регулярно проводим акции и предлагаем скидки. Подписывайтесь на наши соцсети! Доставка по всей России.',
    buttonText: 'Вперед, за покупками!',
};
