export const PERSONS_INFO = {
    samir: {
        phoneNumber: '+7 (964) 837-06-63',
        firstName: 'Самир',
        lastName: 'Мурсагулиев',
        role: 'Директор',
        email: 'mursaguliev@mail.ru',
    },
    we: {
        tgLink: 'https://t.me/store21sportnn',
        phoneNumber: '+7 (908) 767-67-66',
        tg: '@store21sportnn',
        email: 'sport21nino@mail.ru',
    },
    roman: {
        role: 'Создатель',
        tgLink: 'https://t.me/ptprt5m',
        tg: '@ptprt5m',
    },
};

export const COMPANY_INFO = {
    nameEng: '21 sport / store',
    nameRus: '21 Спорт / Стор',
    nameOld: 'Sport Discount Russia',
    address: 'Площадь Максима Горького, 4/2',
    fullAddress: 'Площадь Максима Горького, 4/2. (Вход со стороны площади - цоколь)',
    city: 'Нижний Новгород',
    postCode: '603000',
    country: 'Россия',
    OGRN: '320527500053758',
    TIN: '525915493036',
    OKPO: '2001660731',
    IP: 'ИП "Мурсагулиев Самир Ильхам Оглы"',
    categories: ['Спорт', 'Одежда', 'Обувь', 'Аксессуары'],
    openingHours: '10:00 - 20:00',
    workingDays: 'Ежедневно',
    phones: {
        main: PERSONS_INFO.we.phoneNumber,
    },
    emails: {
        main: PERSONS_INFO.we.email,
    },
    contacts: {
        telegram: {
            link: PERSONS_INFO.we.tgLink,
            name: PERSONS_INFO.we.tg,
        },
    },
    socialNetworks: {
        telegram: {
            link: 'https://t.me/store21sport',
            name: PERSONS_INFO.we.tg,
        },
        instagram: 'https://www.instagram.com/21.sport.nn',
        avito: 'https://www.avito.ru/user/ced19a56b63d090da2cf32414adc08b4/profile?src=fs&page_from=from_favorite_sellers',
    },
    branch: {
        address: 'Улица Пискунова, 21',
        openingHours: '10:00 - 20:00',
        workingDays: 'Ежедневно',
    },
};
