import { NavLink } from 'react-router-dom';
import logo from '../../img/logo/21_logo.png';
import newYear from '../../img/newYear.png';
import { useMobileMenu, useShowNewYearDecor } from '../../hooks';

export const HeaderLogo = () => {
    const { closeMobileMenu } = useMobileMenu();
    const { isShowNewYearDecor } = useShowNewYearDecor();

    return (
        <NavLink to='/' style={{ position: 'relative' }} onClick={closeMobileMenu}>
            <img src={logo} alt='Логотип' className='scale header__logo-img dropShadow' />
            {isShowNewYearDecor && <img src={newYear} alt='Шапочка' className='header__logo-img header__logo-hat' style={{ borderRadius: '0' }} />}
        </NavLink>
    );
};
