import { EMOJI } from '../emoji';

export const TERMS = {
    title: `Правила использования сайтом ${EMOJI.docs[0]}`,
    subtitle:
        'Добро пожаловать на онлайн-платформу 21 Sport, лучшего магазина спортивной одежды, обуви и аксессуаров! Чтобы обеспечить безопасный и приятный опыт для всех пользователей, мы установили следующие правила использования нашего сайта. Получая доступ к нашей платформе и используя ее, вы соглашаетесь соблюдать следующие правила:',
    items: [
        {
            title: 'Регистрация:',
            items: [
                'Пользователи должны быть старше 14 лет, чтобы создать учетную запись на нашем сайте.',
                'Предоставьте точную и актуальную личную информацию в процессе регистрации.',
                'Не создавайте несколько учетных записей и не выдавайте себя за другое лицо при использовании сайта.',
            ],
        },
        {
            title: 'Безопасность учетной записи:',
            items: [
                'Храните ваши учетные данные (имя пользователя и пароль) в конфиденциальности и безопасности.',
                'Не передавайте свою учетную запись другим лицам и не разрешайте несанкционированный доступ.',
                'Немедленно сообщите нам, если вы подозреваете несанкционированное использование вашей учетной записи.',
            ],
        },
        {
            title: 'Поведение пользователей:',
            items: [
                'Уважайте других пользователей и участвуйте в дружеском и конструктивном общении.',
                'Не публикуйте, не делитесь и не передавайте любой контент, который является оскорбительным, клеветническим или нарушает какие-либо применимые законы.',
                'Воздержитесь от рассылки спама, фишинга и любых других злонамеренных действий.',
                'Не участвуйте в каких-либо действиях, которые могут нарушить или помешать работе сайта или ухудшить работу других пользователей.',
            ],
        },
        {
            title: 'Интеллектуальная собственность:',
            items: [
                'Уважайте права интеллектуальной собственности других лиц, включая товарные знаки, авторские права и патенты.',
                'Не загружайте, не делитесь и не используйте любой контент, нарушающий права интеллектуальной собственности третьих лиц.',
            ],
        },
        {
            title: 'Обзоры продуктов:',
            items: [
                'Предоставляйте честные и непредвзятые отзывы при написании отзывов о продуктах.',
                'Не размещайте ложную или вводящую в заблуждение информацию о продуктах или конкурентах.',
                'Избегайте использования отзывов в качестве платформы для продвижения посторонних компаний или веб-сайтов.',
            ],
        },
        {
            title: ' Онлайн-покупки:',
            items: [
                'Убедитесь, что вся личная и платежная информация, предоставляемая для онлайн-покупок, является точной и актуальной.',
                'Соблюдайте наши условия, политику возврата и правила доставки.',
            ],
        },
        {
            title: 'Использование сайта:',
            items: [
                'Используйте сайт исключительно по прямому назначению: просмотр и покупка.',
                'Не пытайтесь изменять, копировать, реконструировать или вмешиваться в дизайн или функциональные возможности сайта.',
                'Соблюдайте любые дополнительные правила или рекомендации, представленные 21 Sport в определенных разделах сайта.',
            ],
        },
        {
            title: 'Конфиденциальность и защита данных:',
            items: [
                'Ознакомьтесь с нашей Политикой конфиденциальности, подробно описывающей сбор, использование, хранение и защиту вашей личной информации.',
            ],
        },
        {
            title: 'Прекращение действия Аккаунта:',
            items: [
                '21 Sport оставляет за собой право закрыть или приостановить действие любой учетной записи пользователя, которая нарушает эти правила или занимается мошенническими или злонамеренными действиями.',
            ],
        },
        {
            title: 'Изменения в Правилах:',
            items: [
                '21 Sport оставляет за собой право изменять или дополнять эти правила в любое время. Пользователи будут уведомлены о любых изменениях, а дальнейшее использование сайта после внесения изменений подразумевает принятие обновленных правил.',
            ],
        },
    ],
    lastText:
        'Пожалуйста, внимательно прочитайте эти правила и убедитесь в их соблюдении. Несоблюдение этих правил может привести к приостановке или прекращению действия вашей учетной записи. Наслаждайтесь нахождением на платформе 21 Sport и занимайтесь спортом!',
};
