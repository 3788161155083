import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { filtersInitialState } from './initialStates/filtersInitialState';
import { api } from '../api';

export const getMinAndMaxPrice = createAsyncThunk('filters/getMinAndMaxPrice', async (_, { dispatch }) => {
    const responseMin = await api.getMinPrice();
    const responseMax = await api.getMaxPrice();
    dispatch(setMinAndMaxPrice([responseMin[0].price, responseMax[0].price]));
});

const filtersSlice = createSlice({
    name: 'filters',
    initialState: filtersInitialState,
    reducers: {
        setFilters: (state, action) => {
            Object.assign(state, action.payload);
        },
        setPrice: (state, action) => {
            state.price = action.payload;
        },
        setSex: (state, action) => {
            state.sex = action.payload;
        },
        setType: (state, action) => {
            state.type = action.payload;
        },
        setFiltersApplied: (state, action) => {
            state.filtersApplied = action.payload;
        },
        setMinAndMaxPrice: (state, action) => {
            state.minPrice = action.payload[0];
            state.maxPrice = action.payload[1];
        },
        setPermissionToRequest: (state, action) => {
            state.permissionToRequest = action.payload;
        },
        resetFilters: (state) => {
            const currentSex = state.sex;
            Object.assign(state, filtersInitialState);
            state.sex = currentSex;
        },
    },
});

export const { setFilters, setPrice, resetFilters, setSex, setType, setMinAndMaxPrice, setPermissionToRequest, setFiltersApplied } = filtersSlice.actions;

export default filtersSlice.reducer;
